@import 'src/styles/imports';

// Mixin for flex centering
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  height: 90vh;
  align-content: center;
  flex: 1;
}

.secondary-container {
  @include flex-center;
  background-color: $color-white;
  text-align: center;
  flex-direction: column;

  image {
    width: 350px;
    height: 350px;
  }

  .heading {
    font-size: 1.5em;
    margin: 1em 0 10px;
    font-family: $font-family-serif;
    font-weight: $font-weight-bold;
  }

  p {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .cta-button {
    width: fit-content;
    margin: 16px 8px 0;
  }
}
