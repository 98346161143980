@import 'src/styles/imports';

.component {
  @include layout-container;
  padding-bottom: 4em;
}

.listing-content {
  position: relative;
  display: flex;
  padding: 4em 0 0;
  flex-direction: row;
  justify-content: center;

  @include desktop {
    display: flex;
    flex-direction: row;
  }
}

.left-section {
  width: 100%;
  margin-right: auto;

  @include desktop {
    width: calc(100% - 20em);
    padding-right: 4.25em;
  }

  .top-bar {
    display: flex;
    font-weight: $font-weight-bold;
    justify-content: space-between;

    a {
      display: flex;
      color: $color-blue;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;
        margin-right: .5em;

        g,
        path {
          fill: $color-blue;
        }
      }
    }

    @include mobile {
      flex-direction: column;

      h2 {
        margin-top: 2em;
        order: 2;
      }
    }
  }

  h1 {
    font-size: 64px;
    margin-top: .3em;
    font-weight: 500;

    @include mobile {
      font-size: 32px;
    }
  }

  .expanded-estimate-cta {
    display: flex;
    width: 100%;
    margin: 1.2em 0;
    color: var(--themes-colors-primary-link-color);
    font-weight: $font-weight-bold;
    text-decoration: underline;
    cursor: pointer;
    flex-wrap: wrap;
  }

  .address {
    margin: 1.5em 0 1em;
    font-weight: $font-weight-bold;
  }

  .stats-container {
    display: flex;
    width: 100%;
    margin: 1.2em 0;
    text-decoration: none;
    flex-wrap: wrap;
  }

  .stat {
    display: flex;
    margin-right: 1em;
    margin-bottom: .5em;
    font-weight: 600;
    align-items: center;

    svg {
      margin-right: .3em;

      &.car {
        height: 1.2em;
      }
    }
  }

  .disclaimer {
    display: inline-flex;
    padding: 16px 12px;
    border-radius: $border-radius-medium;
    margin-top: 1.2em;
    background-color: $color-grey-1;
    cursor: pointer;

    @include mobile {
      font-size: 12px;
    }

    svg {
      width: 30px;
      height: 30px;
      margin-right: .7em;
    }

    p {
      line-height: 24px;
    }
  }

  .cta {
    display: inline-flex;
    padding: 16px 12px;
    border-left: 3px solid var(--themes-colors-primary-link-color);
    border-radius: $border-radius-medium;
    margin-bottom: 4em;
    background-color: $color-blue-10;
    color: var(--themes-colors-primary-link-color);
    cursor: pointer;
    align-items: center;

    svg {
      width: 11px;
      height: 11px;
      margin-right: .7em;

      path {
        stroke: var(--themes-colors-primary-link-color);
      }
    }

    p {
      line-height: 24px;

      &:nth-child(2) {
        font-weight: $font-weight-bold;
        text-decoration: underline;
      }
    }
  }
}

.request-info-section {
  position: absolute;
  right: 0;
  display: block;
  width: 20em;
  height: 100%;

  @include not-desktop {
    display: none;
  }

  > div {
    position: sticky;
    top: 100px;
  }
}

.pvr-cta-wrapper {
  position: fixed;
  z-index: $z-index-pvr-button;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: $box-shadow-top;
  padding: .7em 1em 1em;
  border-top-color: $color-grey-2;
  border-top-width: 1px;
  background-color: $color-white;

  .pvr-cta-button {
    font-size: 1.125em;
    box-shadow: $box-shadow;
    width: 100%;
    padding: 1em .5em;
    font-weight: 600;
  }
}

.empty {
  display: flex;
  max-width: 600px;
  padding: 4em 0;
  margin: 0 auto;
  text-align: center;
  align-items: center;
  flex-direction: column;

  .zoopraisal-estimate {
    font-size: 16px;
    padding-top: 1em;
    color: $color-grey-17;
    font-weight: $font-weight-bold;
  }

  h3 {
    font-size: 32px;
    margin: .8em 0 .6em;
    font-family: $font-family-serif;
    font-weight: $font-weight-bold;
  }

  p {
    line-height: 24px;
    color: $color-grey-17;
  }

  button {
    font-size: 16px;
    width: 90%;
    max-width: 280px;
    padding: 16px 24px;
    margin: 1.5em 0;
  }

  a {
    padding: .7em 0;
    border-bottom: 1px solid $color-grey-3;
    text-decoration: none;
  }
}

.is-loading {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.spacer {
  height: 3em;

  @include mobile {
    height: 0;
  }
}

.exp {
  .top-bar {
    h2 {
      font-weight: $font-weight-bold;
    }

    a {
      color: $color-dark-navy;
      opacity: .8;

      svg g,
      svg path {
        fill: $color-dark-navy;
      }
    }
  }

  .left-section {
    h1 {
      font-weight: 600;
    }

    .address {
      color: $color-dark-navy;
      opacity: .8;
    }
  }

  .cta {
    background-color: $color-dark-navy-10;
  }

}

.similar-heading {
  font-size: 38px;
  display: block;

  @include mobile {
    font-size: 24px;
    margin-top: -1.5em;
  }
}

.similar-sold b {
  color: var(--themes-colors-primary-sold);
}

.similar-available b {
  color: var(--themes-colors-primary-link-color);
}
