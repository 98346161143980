@import 'src/styles/imports';

.container {
  display: flex;
  margin-top: 10px;
  flex-direction: row;

  .img {
    width: 36px;
    min-width: 36px;
    height: 36px;
    margin-right: 6px;

    img {
      width: 36px;
      height: 36px;
    }
  }
}

.component {
  display: flex;
  padding: 11px 10px;
  border-radius: 10px;
  background-color: $color-grey-13;
  align-items: center;
  justify-content: center;
}

.component > div {
  width: .4em;
  height: .4em;
  border-radius: 50%;
  margin: 3px;
  background-color: $color-grey-9;
  opacity: 1;
  animation: component .5s infinite alternate;
}

@keyframes component {
  to {
    opacity: .1;
    transform: translateY(-1px);
  }
}

.component > div:nth-child(2) {
  animation-delay: .1s;
}

.component > div:nth-child(3) {
  animation-delay: .2s;
}
