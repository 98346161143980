@import '/src/styles/imports';

.component {
  color: $color-grey-9;

  @include not-mobile {
    min-width: 20em;
    max-width: 25em;
  }
}

.rating {
  font-size: .875em;
  display: flex;
  margin-top: 1em;
  align-items: center;
}

.select-row {
  display: grid;
  margin-bottom: 1em;
  align-items: baseline;
  grid-template-columns: 1fr 1fr;
}

.warning { //16px => 10px
  font-size: .625em;
  display: flex;
}

.boundary-warning { //16px => 10px
  font-size: .8125em;
  display: flex;
  color: $color-yellow;
  align-items: center;
}

.warning-icon {
  width: 4em;
  height: 4em;
  margin-right: 1em;

  path,
  circle {
    fill: $color-yellow;
  }
}

.title {
  font-size: 1.875em;
  display: flex;
  padding-bottom: .8em;
  border-bottom: 1px solid $color-grey-3;
  margin-bottom: .5em;
  flex-direction: row;
  justify-content: space-between;
}

.school-title {
  margin-bottom: .75em;
}

.line {
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid $color-grey-3;
  margin: 1em 0;
}

.row {
  display: flex;
  margin-top: 1em;
  flex-direction: row;
  justify-content: space-between;
}

.range-section {
  width: 100%;
}

.toggle-section {
  display: flex;
  margin-bottom: .5em;
  justify-content: space-between;

  .toggle-custom-style {
    label {
      font-size: 16px;
    }
  }

  .warning {
    width: 75%;
    margin-top: .5em;
  }
}
