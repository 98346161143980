@import 'src/styles/imports';

.component {
  box-shadow: var(--themes-box-shadow);
  padding-top: 1.5em;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: var(--themes-colors-secondary-background);

  @include desktop {
    padding: 1.5em;
    border: 1px solid $color-grey-3;
    border-radius: 8px;
  }

  h3 {
    font-size: 24px;
    position: relative;
    display: inline-block;
    font-family: $font-family-serif;
    font-weight: $font-weight-bold;
  }

  .note {
    font-size: 14px;
    line-height: 20px;
    margin: 1.5em 0 1em;
  }

  > div {
    margin-top: .7em;

    input,
    textarea {
      border-radius: $border-radius-large;
      background-color: var(--themes-colors-tertiary-background);
    }
  }

  button {
    font-size: 16px;
    width: 100%;
    padding: 16px;
    margin-top: 1.5em;
    font-weight: 500;
  }

  .add-comment {
    font-size: .875em;
    display: flex;
    margin-bottom: 1em;
    color: $color-grey-9;
    cursor: pointer;
    align-items: center;

    &:hover {
      color: $color-grey-6;
      text-decoration: underline;

      g {
        fill: $color-grey-6;
      }
    }

    svg {
      margin-right: .5em;

      g {
        fill: $color-grey-9;
      }
    }
  }
}

.modal {
  background-color: $color-white;

  .icons {
    display: flex;
    margin-bottom: 1.5em;
    align-items: baseline;
    justify-content: center;

    svg {
      margin: 0 .6em;
    }
  }

  h3 {
    font-size: 1.6em;
    font-family: $font-family-serif;
  }

  .note {
    margin: 1.5em 0 .5em;
  }

  > div {
    margin-top: .5em;
  }

  textarea {
    height: 5em;
  }
}
