@import 'src/styles/imports';

.component {
  display: flex;
  justify-content: center;

  &.exp-theme {
    .phone-number {
      border-radius: 8px;
      color: var(--themes-colors-primary-link-color);
    }

    .custom-text-area {
      font-size: .875em;
      border-radius: $border-radius-large;
    }
  }

  @include desktop {
    max-width: 55em;
  }

  .right {
    @include desktop {
      padding: 2em;
    }

    h1 {
      font-size: 2.125em;
      margin-top: 1.5em;
      margin-bottom: 1em;
    }

    p {
      font-size: .875em;
      margin-bottom: 1em;
    }

    li {
      font-size: .875em;
      line-height: 1.5em;
    }

    line {
      display: block;
      width: 100%;
      padding: .5em 0;
      border-bottom: .5px solid $color-grey-3;
    }

    .radio-group {
      padding: 1em 0;
    }
  }
}

.image {
  display: none;
  @include desktop {
    display: block;
    width: 50%;
    object-fit: cover;
  }

}

.inputs {
  display: flex;
  flex-direction: column;

  @include not-mobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
  }

  &.just-email {
    @include not-mobile {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}

.deal-icon {
  display: block;
  margin: auto;
  margin-top: 1em;

  @include desktop {
    display: none;
  }
}

.call {
  margin-top: 1em;
}

.phone-number {
  margin-left: .5em;
  color: $color-blue;

  &:hover {
    color: $color-blue-dark;
  }
}

.success-container {
  .success-message {
    @include not-mobile {
      padding: 3em 2em 2em;
    }
  }
}

.newsletter {
  line-height: 1.2em;
}

.button {
  width: 100%;
  margin-top: 1em;
}

.add-comment {
  font-size: .875em;
  display: flex;
  margin: 1em 0;
  color: $color-grey-9;
  cursor: pointer;
  align-items: center;

  &:hover {
    color: $color-grey-6;
    text-decoration: underline;

    g {
      fill: $color-grey-6;
    }
  }

  svg {
    margin-right: .5em;

    g {
      fill: $color-grey-9;
    }
  }
}

.hide {
  display: none;
}

.disclaimers {
  margin-top: 1em;
}

.disclaimer-error {
  font-size: .875em;
  margin-top: .5em;
  color: $color-red;
  text-align: center;
}
