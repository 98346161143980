@import 'src/styles/imports';

.component {
  @include not-mobile {
    width: 22em;
  }

  h1 {
    font-size: 1.5em;
    line-height: 1.2em;
    margin-bottom: 1em;
  }

  p {
    font-size: .875em;
    line-height: 1.5em;
  }

  .disclaimer {
    @include scroll-y;
    @include no-scrollbar;
    height: 15.5em;

    h2 {
      font-size: 1em;
      margin-bottom: .5em;
    }

    ul {
      margin-bottom: 1em;

      li {
        display: flex;
        flex-direction: row;

        .bullet-point {
          margin-top: .25em;
          margin-right: .3em;
        }

        span {
          font-size: .8em;
          line-height: 1.8em;
          margin: .1em 0;
        }
      }
    }
  }

  .checkbox {
    margin-top: 1em;
  }

  .button {
    width: 100%;
    height: 50px;
    margin-top: 1.5em;
  }
}
