@import 'src/styles/imports';

.component {
  position: absolute;
  z-index: $z-index-dropdown;
  top: 75%;
  right: 6em;
  box-shadow: $box-shadow;
  width: 16em;
  min-height: 5.5em;
  padding: 1em 0 .5em 1em;
  background: $color-white;

  .heading {
    margin-bottom: .5em;
    text-align: left;
  }

  .flag-container {
    display: flex;
    flex-direction: row;

    .flag-radio-button {
      display: flex;
      padding-right: .5em;
      padding-left: 2.5em;
      flex-direction: column;

      img {
        width: 45px;
        height: 35px;
        margin-bottom: .5em;
      }
    }
  }
}

.component-panel {
  font-size: 1.125em;
  position: inherit;
  box-shadow: none;
  padding: 0;
  background: $color-grey-10;
}
