@import 'src/styles/imports';

.component {
  display: flex;
  padding: 3rem 1rem;
  background-color: $color-white;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .content {
    display: flex;
    max-height: 60%;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .message {
      width: auto;
      height: auto !important;
      line-height: 20px;
      padding-top: 2rem;
    }
  }
  
}