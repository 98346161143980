// sass-lint:disable no-misspelled-properties
@import 'src/styles/imports';

.component {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include not-mobile {
    width: 23em;
  }

  h1 {
    font-size: 1.5em;
    line-height: 1.3em;
    margin: 0 !important; // sass-lint:disable-line no-important
    font-weight: $font-weight-bold;
  }

  .warning {
    margin-top: 1em;
    color: $color-yellow;

    svg {
      float: left;
      width: 1.5em;
      margin-top: .2em;

      path,
      circle {
        fill: $color-yellow;
      }
    }

    p {
      font-size: .9375em;
      float: right;
      width: 90%;
      line-height: 1.4em;
      font-weight: $font-weight-bold;
    }
  }

  .disclaimer {
    font-size: .9375em;
    margin: 1em 0 .5em;

    li {
      display: flex;
      line-height: 1.5;
      margin-bottom: .5em;

      svg {
        width: 1em;
        height: .6em;
        margin: .4em .3em 0 0;
        flex-shrink: 0;
      }
    }
  }

  .description {
    font-size: .875em;
    line-height: 1.5em;
    margin-top: 1em;
    word-break: break-word;

    b {
      font-weight: $font-weight-bold;
    }
  }

  .centered-text {
    text-align: center;
  }

  .forgot-password {
    font-size: .8125em;
    margin: .5em 0 0;
  }

  div {
    input[type='text'],
    input[type='email'],
    input[type='password'] {
      font-size: .875em;
      background-color: $color-white !important;

      &:hover {
        border-color: var(--themes-colors-primary-border-color) !important;
      }

      &:focus {
        border-color: var(--themes-colors-primary-border-color) !important;
      }
    }
  }

  button {
    font-size: 1em;
    width: 100%;
    margin-top: 1em;
  }

  svg {
    margin-top: 1em;
    margin-bottom: 1em;

    &.house {
      margin: 1.5em auto;
    }
  }

  a {
    border-bottom: 0;
  }

  .email-display {
    line-height: 24px;
    margin: .5em 0;
  }

  .checkbox {
    margin-top: .5em;

    &:hover {
      font-weight: $font-weight-regular;
    }

    label {
      line-height: 1.4;
    }
  }

  .terms {
    font-size: 9.5pt;
    line-height: 1.5em;
    margin-top: 1em;
    margin-bottom: 0 !important; // sass-lint:disable-line no-important
    color: $color-black-65;
    text-align: center;
  }

  .greeting {
    margin-right: auto;
    margin-bottom: 2em;
    margin-left: auto;
  }

  .privacy {
    font-size: 12px;
    line-height: 1.5em;
    padding: 1em;
    border-radius: $border-radius-medium;
    margin-top: 1em;
    background-color: $color-black-10;
    color: $color-grey-6;
  }

  .disclaimer-error,
  .error {
    font-size: .8em;
    margin-top: .5em;
    color: $color-red;
    text-align: center;
  }

  &.welcome {
    &.exp-theme {
      h1 {
        margin-bottom: 8px;
      }

      p {
        line-height: 24px;
      }

      img {
        margin: 1.5em auto;
      }
    }
  }
}

.login-registration {
  display: flex;
  min-height: 300px;

  .back-button-container {
    display: none;
  }

  @include desktop {
    max-width: 100%;

    .back-button-container {
      position: absolute;
      top: -1.7em;
      display: inline;

      svg {
        width: .7em;
        margin: 0;
      }
    }
  }

  @include not-mobile {
    .half {
      display: flex;
      justify-content: space-between;

      > div {
        width: calc(50% - .5em);
      }
    }
  }

  .sso {
    .or {
      position: relative;
      margin: 2em 0 3em;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $color-black-10;
      }

      p {
        position: absolute;
        top: -10px;
        left: calc(50% - 30px);
        padding: 0 1.5em;
        background-color: $color-white;
        color: $color-grey-8;
      }
    }
  }
}

.email-used {
  font-size: 14px;
  padding: 1em 0 .5em;

  .sing-in-now {
    color: $color-blue;
    text-decoration: underline;
    cursor: pointer;
  }
}

.one-secure-login {
  display: flex;
  padding: 16px 0;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 28px;
  background: $color-grey-1;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  gap: 10px;

  .theme-logos {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  .zoocasa-icon-wrapper {
    display: flex;
    width: 89px;
    height: 24px;
    padding: .5em;
    background: $color-white;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
  }
}

.one-secure-login-description {
  font-size: 16px;
  display: flex;
  line-height: 28px;
  font-weight: 400;
  flex-direction: column;
  gap: 16px;

  .origin-url {
    color: $color-blue;
    font-weight: 600;
  }
}
