@import 'src/styles/imports';
// sass-lint:disable no-important
// sass-lint:disable no-misspelled-properties

:export {
  sidePanelBreakpoint: $breakpoint-side-panel;
  sidePanelSmall: $width-side-panel-small;
  sidePanelEnlarged: $width-side-panel-enlarged;
}

.component {
  display: none;
  overflow-y: hidden;

  &.is-mobile {
    display: block;

    .mobile-listing-filters {
      margin: 16px;

      .listing-header {
        margin: 16px 0;
        margin-bottom: 16px;
        font-family: var(--themes-font-text);
        font-weight: 700;
      }

      .input-wrapper {
        display: flex;
        margin: 8px 0;

        .padded-button {
          margin-right: 8px;
          margin-left: 8px;
        }

        .save-search {
          fill: none;
          stroke: var(--themes-colors-primary-font-color);
        }
      }
    }
  }

  @include desktop {
    display: block;
    width: $width-side-panel-small;
    height: calc(100vh - $height-zoo-top-nav-offset);
    background: $color-white;
    filter: drop-shadow(0 1px 2px $color-black-65);
    flex: 0 0 auto; // side panel won't grow, shrink, or take extra space

    &.exp-height {
      height: calc(100vh - $height-exp-top-nav-offset);
    }

    @media (min-width: $breakpoint-side-panel) {
      width: $width-side-panel-enlarged;
    }
  }
}

.full-screen {
  overflow: hidden;
  width: 100vw;

  .sort {
    display: inline-flex;
    padding-left: 0;

    .sort-input-wrapper {
      width: 13em;
    }
  }
}

.sort {
  display: flex;
  padding: 1em 1em 0;
  margin-bottom: 1em;
  align-items: baseline;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: $breakpoint-side-panel) {
    flex-direction: row;
  }

  .listing-count {
    font-size: 1.1em;
    width: 75%;
    margin-right: .5em;
    margin-bottom: .5em;
    font-weight: $font-weight-bold;
    white-space: nowrap;

    @media (min-width: $breakpoint-side-panel) {
      width: auto;
    }
  }

  .sort-input-wrapper {
    width: 13em;
  }

  .hide-panel-wrapper {
    display: flex;
    margin-right: 1em;
    justify-content: flex-end;
    flex: 1 1 auto;

    svg {
      width: .85em;
      height: .85em;
    }
  }
}

.show-panel-wrapper {
  position: absolute;
  z-index: $z-index-map-panel;
  top: 6.3em;
  left: 5em;

  button {
    svg {
      width: .85em;
      height: .85em;
    }
  }
}

.scrollable {
  height: 100%;

  .list-grid > div:first-of-type {
    height: auto !important; // Prevent 3rd party dependency height becoming too long
  }

  .disclaimer-button-wrapper {
    display: flex;
    justify-content: center;
  }
}

.empty-state {
  font-size: 1.125em;
  display: flex;
  margin-top: 2em;
  color: var(--themes-colors-map-pin-for-sale);
  text-align: center;
  align-items: center;
  flex-direction: column;

  svg {
    margin-bottom: 1em;
  }

  .empty-warning {
    g {
      fill: $color-grey-9;
    }
  }
}
