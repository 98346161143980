@import 'src/styles/imports';

.component {
  position: relative;
  display: flex;
  padding: 1em;
  border: 1px solid $color-grey-4;
  border-radius: var(--themes-buttons-border-radius-medium);
  margin: 1em 0;
  flex-direction: row;

  @media screen and (max-width: 1000px) {
    margin-bottom: 3em;
    flex-direction: column;
  }

  h2 {
    font-size: 1.125em;
    margin-bottom: .5em;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-bold;

    @include desktop {
      font-size: 1.375em;
      margin-bottom: .8em;
    }

    @media screen and (max-width: 1000px) {
      margin-top: 2em;
    }
  }

  .image-wrapper {
    position: relative;
    width: 25%;
    min-width: 200px;
    height: 200px;

    @media screen and (max-width: 1000px) {
      margin: auto;
    }
  }

  .link {
    line-height: 36px;
    padding-left: 1.4em;
    font-weight: $font-weight-thin;
    text-indent: -1.4em;

    @media screen and (max-width: 1000px) {
      width: 85%;
    }

    .sparkle {
      margin-right: .4em;
      vertical-align: middle;
    }

    .arrow {
      margin-left: .1em;
      vertical-align: -15%;
    }
  }

  .button {
    padding: 1em 2em;
    margin-top: 1em;
    font-weight: $font-weight-bold;

    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }
}
