@import 'src/styles/imports';

.component {
  width: 99%;
  height: 100%;
  border: 1px solid $color-grey-1;

  &.exp-home {
    box-shadow: var(--themes-box-shadow);
    border-radius: $border-radius-medium;
  }

  .top {
    position: relative;
    display: flex;
    border-radius: $border-radius-medium;
    margin-bottom: .6em;
    background-color: $color-grey-10;
  }

  .image {
    position: absolute;
    z-index: 0;
    right: -40%;
    opacity: .14;
  }

  .text {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    color: $color-white;
    text-align: center;
    flex-direction: column;
    justify-content: center;

    p {
      margin-bottom: 1em;
    }

    a {
      border-bottom: 0;
      text-decoration: none;
    }

    button {
      padding: .5em 2em;
      margin: 0 auto;
    }
  }

  .bottom {
    font-size: .875em;
    min-height: 8.5em;
    line-height: 1.5;
    padding: .5em;
    white-space: normal;
  }
}
