@import 'src/styles/imports';

$component-width: 300px;

.component {
  width: 100%;
  padding: 1em;
  border-radius: var(--themes-buttons-border-radius-medium);
  margin: 1.5em 0;
  background-color: $color-exp-secondary-dark-navy-5;

  &.component-floating {
    position: absolute;
    z-index: $z-index-bump;
    bottom: 3em;
    left: calc(50% - $component-width/2);
    width: $component-width;
    margin: 0;

    .close-button {
      position: absolute;
      top: 1.1em;
      right: 1.1em;
      width: 14px;
      height: 14px;
      padding: 0;
      font-weight: $font-weight-light;
      cursor: pointer;
    }

    .agent-name {
      padding-right: 1.4em;
    }
  }

  .popover-content {
    display: flex;
    flex-direction: row;

    .avatar-large {
      width: 40px;
      height: 40px;
      border: 1px solid $color-black-10;
      border-radius: 50%;
      margin-right: 13px;
    }

    .popover-agent-info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .agent-name {
        font-size: 14px;
        line-height: 1.2em;
      }
    }
  }
}
