@import 'src/styles/imports';

.component {
  @include not-mobile {
    width: 22em;
  }

  h1 {
    font-size: 1.5em;
    margin-bottom: 1em;
  }

  .frequency {
    font-size: .875em;
    line-height: 1.5;
    margin: 1em 0;
  }

  .button {
    width: 100%;
    height: 50px;
    border-radius: var(--themes-buttons-border-radius);
    margin-top: 1.5em;
  }

}
