// sass-lint:disable no-important
@import 'src/styles/imports';

button {
  &.authbtn {
    display: flex;
    width: 100%;
    height: 50px;
    padding: 1em;
    border-color: $color-grey-12 !important;
    border-radius: var(--themes-buttons-border-radius-large);
    background-color: $color-black-05 !important;
    color: $color-grey-10 !important;
    font-weight: $font-weight-semibold;

    &:hover {
      border-color: $color-black-75 !important;
      background-color: $color-grey-4 !important;
      color: $color-black !important;
    }

    svg {
      width: 1.6em;
      height: 1.6em;
      margin-top: 0 !important;
      margin-right: .5em;
      margin-bottom: 0 !important;
    }
  }
}
