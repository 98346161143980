@import 'src/styles/imports';

.notification-banner {
  position: fixed;
  z-index: calc($z-index-app-banner - 1);
  bottom: 20px;
  left: 50%;
  display: flex;
  box-shadow: 0 4px 10px $color-black-10;
  min-width: 470px;
  max-width: 662px;
  padding: 15px 20px;
  border-radius: 10px;
  background-color: $color-dark-navy-bg;
  align-items: center;
  transform: translateX(-50%);

  .notification-content {
    display: flex;
    margin-right: 20px;
    align-items: center;
    flex-grow: 1;

    .notification-icon {
      margin-right: 15px;

      svg {
        width: 17px;
        height: 18px;
      }
    }

    p {
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      font-family: var(--themes-fonts-text);
      font-style: normal;
      font-weight: 400;
      letter-spacing: .25px;
      user-select: none;

      strong {
        font-weight: bold;
      }
    }
  }

  .close-button {
    font-size: 14px;
    line-height: 20px;
    padding: 8px 20px;
    border: 0;
    border-radius: var(--themes-buttons-border-radius);
    background-color: $color-grey-6;
    color: $color-white;
    font-family: var(--themes-fonts-text);
    font-style: normal;
    font-weight: 700;
    letter-spacing: .25px;
    cursor: pointer;
    white-space: nowrap;
    user-select: none;

    &:hover {
      background-color: $color-grey-8;
    }
  }
}
