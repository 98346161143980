@import 'src/styles/imports';

.component {
  line-height: 1.5em;
  padding: 1.5em;
  border: 1px solid $color-grey-3;
  border-radius: var(--themes-buttons-border-radius-medium);

  h2 {
    font-size: 1.5em;
    display: flex;
    margin-bottom: 1em;
    color: $color-grey-6;
    font-family: $font-family-sans-serif;
    font-weight: 700;
    align-items: flex-start;
  }

  .zoocasa-link {
    margin-top: 24px;

    a {
      color: var(--themes-colors-primary-link-color);
      font-weight: 700;

      &:focus {
        color: var(--themes-colors-primary-focus-link-color);
        text-decoration: underline;
      }
    }
  }

  b {
    display: contents;
    font-weight: $font-weight-bold;
  }

  svg {
    width: 1.65em;
    height: 1.65em;

    path,
    polygon {
      fill: var(--themes-colors-primary);
    }
  }
}

.stat-row {
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.grey-icon {
  display: flex;
  width: 3.25em;
  min-width: 3.25em;
  height: 3.25em;
  min-height: 3.25em;
  border-radius: 3em;
  margin-right: 1em;
  background: $color-grey-7;
  align-items: center;
  justify-content: center;
}

.estimation-disclaimer-text {
  font-size: 14px;
}

.contact-button {
  font-size: 14px;
  width: 150px;
  height: 48px;
  border: 1px solid var(--themes-colors-secondary-border-color);
  border-radius: var(--themes-buttons-border-radius);
  margin-top: 16px;
  margin-bottom: 8px;
  background-color: #f6f4f4;
  color: #181b1e;
  font-weight: 700;

  &:hover{
    background-color: var(--themes-colors-primary-dark);
    color: #fff;
  }

  &:focus {
    border-color: var(--themes-colors-secondary-focus-border-color);
    background-color: var(--themes-colors-secondary-focus-color);
    color: var(--themes-colors-secondary-background);
  }
}

.disclaimer {
  font-size: .75em;
  display: flex;
  padding: 1em;
  border-radius: 8px;
  background-color: $color-grey-7;
  align-items: center;

  &.exp-theme {
    border-radius: 8px;
    background-color: $color-dark-navy-bg;
  }

  .deal-icon {
    width: 12em;
    height: 12em;
    margin-right: 1em;

    @include desktop {
      width: 6em;
      height: 6em;
    }
  }
}


.contact {
  color: var(--themes-colors-primary-link-color);
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.superscript {
  font-size: .5625em;
  margin-top: -5px;
}
