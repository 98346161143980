@import 'src/styles/imports';

.component {
  position: fixed;
  z-index: $z-index-fixed-footer;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  max-width: calc(55em + 6em);
  padding: 1.5em;
  background-color: $color-white;
  justify-content: space-between;

  .reset-button {
    display: none;
  }

  div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  button {
    max-width: 100%;
    padding: 1em 2em;

    &.single {
      width: 100%;
    }
  }

  @include not-large-mobile {
    position: static;
    padding: 1.5em 0;
    margin-bottom: -3em;

    .reset-button {
      display: block;
    }

    div {
      width: auto;

      button {
        margin-left: 2em;
      }
    }
  }
}
