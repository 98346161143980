@import 'src/styles/imports';

.component {
  position: relative;
  z-index: $z-index-home-module-swiper;

  .arrow {
    display: none;
    opacity: 0;

    @include desktop {
      display: flex;
    }
  }

  &:hover .arrow,
  &:active .arrow {
    opacity: 1;
  }

  .slider {
    min-height: 22em;
    padding-bottom: 1em;
  }

  .card {
    position: relative;
    display: inline-block;
    width: calc(100% - 60px);
    background-color: $color-white;

    @media (min-width: $breakpoint-tablet) {
      height: 390px;
    }

    @media (min-width: $breakpoint-mobile) {
      width: 314px;
    }
  }
}
