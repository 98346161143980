@import 'src/styles/imports';

.component {
  padding-bottom: 10em;

  .grid {
    display: grid;
    padding: 1.25em 0;
    grid-template-columns: minmax(0, 26 * 16 * 1em);
    grid-gap: .7em;

    @include not-mobile {
      grid-template-columns: repeat(2, minmax(0, 26 * 16 * 1em));
    }

    * div {
      height: 100%;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
