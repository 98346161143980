// sass-lint:disable no-important
@import 'src/styles/imports';

.component {
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  &.black {
    background-color: $color-black;
  }

  .new-overlay-text {
    position: absolute;
    padding: 16px 24px;
    border: 1px solid $color-white;
    border-radius: 32px;
    background-color: $color-white;
    text-align: center;
    transition: all .2s ease-in-out;

    p {
      font-size: 16px;
      -webkit-background-clip: text;
      background-clip: text;
      color: $color-dark-navy;
      font-family: 'Manrope';
      font-weight: 500;
      transition: color .2s ease-in-out;
    }
  }

  .small-text {
    font-size: .85em;
  }

  &:active {
    .new-overlay-text {
      background-color: $color-dark-navy-80 !important;
    }
  }

  &:hover {
    .new-overlay-text {
      border-color: $color-dark-navy;
      background-color: $color-dark-navy;
    }

    p {
      color: $color-white;
    }
  }
}
