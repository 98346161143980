// sass-lint:disable no-important
@import 'src/styles/imports';

.position {
  position: fixed;
  z-index: $z-index-chat-widget;
  bottom: 3em;
  left: 1.5em;

  @include not-desktop {
    position: initial;
  }
}

.chat-button {
  display: flex;
  box-shadow: $box-shadow-popup;
  width: 54px;
  height: 54px;
  border: 1px solid $color-white;
  border-radius: 50px;
  background-color: $color-black;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  @include not-desktop {
    width: 36px;
    height: 36px;

    svg {
      font-size: 18px;
    }
  }
}

.opened-chat {
  border-color: $color-transparent;
  margin-top: 10px;
}

.popup {
  font-size: 14px;
  position: absolute;
  top: -10px;
  left: 70px;
  box-shadow: $box-shadow-popup;
  width: 240px;
  padding: 16px;
  border-radius: 10px;
  background: $color-white;
  cursor: pointer;

  @include not-desktop {
    top: 80px;
    right: 15px;
    left: auto;
  }

  .nib {
    position: absolute;
    top: 25%;
    left: -10px;
    width: 0;
    height: 0;
    border-top: 7px solid $color-white;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    transform: rotate(90deg);
    transition: all .2s ease-in-out;

    @include not-desktop {
      top: -7px;
      left: 38%;
      transform: rotate(180deg);
    }

  }

  &.modal {
    .nib {
      @include mobile {
        left: 53%;
      }
    }
  }

  .close-popup {
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    width: 27px;
    height: 27px;
    padding: 5px;
    border-radius: 50px;
    background-color: $color-black;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    @include not-desktop {
      right: auto;
      left: -10px;
      border: 1px solid $color-white;
    }
  }
}

.header {
  position: relative;
  display: flex;
  padding-bottom: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;

  h3 {
    margin-left: .5em;
    font-weight: 600;
  }

  .close-chat {
    font-size: 20px;
    position: absolute;
    right: 10px;
    cursor: pointer;
  }

  .info {
    margin-left: .5em;
  }

  .info-popup {
    position: absolute;
    z-index: $z-index-bump;
    top: 30px;
    width: 320px;
    padding: 1em;
    padding-top: 1em;
    padding-right: 2.3em;
    border-radius: $border-radius-medium;
    background-color: $color-grey-6;

    p {
      font-size: .85em;
      line-height: 1.5em;
      color: $color-white;
      text-align: left;
    }

    .nib {
      position: absolute;
      top: -7px;
      left: 77.5%;
      width: 0;
      height: 0;
      border-top: 7px solid $color-grey-6;
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      transform: rotate(180deg);
      transition: all .2s ease-in-out;
    }
  }

  svg path {
    fill: $color-black !important;
  }

  .popupclose  {
    position: absolute;
    top: 10px;
    right: 10px;

    path {
      fill: $color-white !important;
    }
  }
}

.chat {
  box-shadow: $box-shadow-dense;
  width: 365px;
  padding: 1em 0;
  border-radius: $border-radius-large;
  background-color: $color-white;

  @include not-desktop {
    position: fixed;
    z-index: $z-index-chat-widget;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .messages-container {
    position: relative;
    overflow-y: scroll;
    min-height: 300px;
    max-height: 500px;
    padding: 0 1em;

    @include not-desktop {
      height: calc(100% - 100px);
      min-height: none;
      max-height: none;
      padding-bottom: 40px;
    }
  }

  .smart-suggestions {
    position: absolute;
    bottom: 0;
    left: calc(50% - 140px);
    display: flex;
    width: 280px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    .smart {
      padding: 11px 15px;
      border-radius: $border-radius-large;
      margin: 4px;
      background-color: $color-black;
      cursor: pointer;

      &:hover {
        background-color: $color-black-80;
      }

      p {
        font-size: 12px;
        color: $color-white;
      }
    }
  }

  .img {
    width: 36px;
    min-width: 36px;
    height: 36px;
    margin-right: 6px;

    img {
      width: 36px;
      height: 36px;
    }
  }

  .messages {
    font-size: 14px;
    display: flex;
    height: 100%;
    align-items: baseline;
    flex-direction: column;
    justify-content: flex-end;

    @include not-desktop {
      justify-content: initial;
    }
  }

  .bubble {
    max-width: 293px;
    padding: 11px 20px;
    border-radius: $border-radius-large;
    margin-top: .5em;

    a {
      color: $color-black;
      text-decoration: underline;
    }
  }

  .user-container {
    margin-left: auto;
  }

  .assistant-container {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
  }

  .user {
    background-color: $color-black;
    color: $color-white;
    text-align: left;
  }

  .assistant {
    background-color: $color-grey-13;
  }

  .input-container {
    display: flex;
    padding: 0 1em;
    align-items: center;
    flex-direction: row;

    > div:first-of-type {
      flex-grow: 1;
    }
  }

  .input {
    width: 100%;
    border-radius: 50px;
  }

  .send {
    margin-top: 1em;
    margin-left: 15px;
    cursor: pointer;

    svg {
      path {
        fill: $color-grey-14 !important;
      }
    }

    &.active {
      svg {
        path {
          fill: $color-sand !important;
        }
      }
    }
  }
}
