@import 'src/styles/imports';

.component {
  font-size: 16px;
  display: flex;
  overflow: hidden;
  width: 100%;
  line-height: 135%;
  border-radius: 12px;
  background-color: #F6F6F5;
  color: $color-dark-navy;
  font-style: normal;
  font-weight: 700;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  transition: all $transition-duration;

  .image-wrapper {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    flex-shrink: 0; 

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: .3s ease;

      &:hover {
        transform: scale(1.07);
      }
    }
  }

  .text-wrapper {
    width: 100%;
    padding: 16px;
    flex-grow: 1;
    gap: 9px;
  }

  .article-title {
    font-size: 16px;
    display: -webkit-box;
    overflow: hidden;
    width: 100%;
    line-height: 1.5em;
    margin-top: -.25em;
    margin-bottom: .4em;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .date {
    font-size: 14px;
    display: flex;
    line-height: 20px;
    color: #757678;
    font-weight: 400;
    letter-spacing: 0.25px;
    align-items: center;
  }
}

:has(> .component) {
  display: grid;
  margin-right: 36px;
  grid-template-columns: 50% 25% 25%;
  gap: 1em;

  @media (max-width: 1250px) {
    margin-right: 0;
  }
}

.component:first-child {
  grid-row: span 2;

  .text-wrapper {
    padding: 28px;
    gap: 20px;
  }
  
  .article-title {
    font-size: 25px;
    margin-bottom: 1em;
  }

  .date {
    font-size: 18px;
  }
}

@media (max-width: 1250px) {
  :has(> .component) {
    display: flex;
    flex-direction: column;
  }

  .component {
    font-size: 20px;
    display: flex;
    width: 100%;
    line-height: 145%; /* 29px */
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #F6F6F5;
    color: #191B1E;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.44px;
    align-items: center;
    flex-direction: row;
    gap: 5px;
    transition: all $transition-duration;
    
    .image-wrapper {
      overflow: hidden;
      width: 14.375em;
      border-radius: 8px;
      flex-shrink: 1;
    }
    
    .text-wrapper {
      width: calc(100% - 10.3125em);
      height: 100%;
      margin-right: 70px;
      flex-grow: 1;
    }

    .date {
      font-size: 14px !important;
      line-height: 20px; /* 125% */
      color: #757678;
      font-family: Mulish;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.25px;
    }

    .article-title {
      font-size: 14px !important;
      -webkit-line-clamp: 3;
    }
  }

  .component:first-child {
    .text-wrapper {
      padding: 16px;
      gap: 9px;
    }
    
    .article-title {
      margin-top: -.25em;
      margin-bottom: .4em;
    }
  
    .date {
      font-size: 16px;
    }
  }

  .component:nth-last-child(-n+2) {
    display: none;
  }
}

@media (max-width: calc($breakpoint-tablet + 20px)) {
  .text-wrapper {
    margin-right: 30px !important;
  }
}

@media (max-width: calc($breakpoint-mobile + 20px)) {
  .text-wrapper {
    margin-right: 10px !important;
  }
}

@media (max-width: $breakpoint-mobile) {
  .image-wrapper {
    width: 8.7em !important;
  }
}
