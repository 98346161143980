// sass-lint:disable no-important
@import 'src/styles/imports';

.component {
  position: relative;
  display: flex;
  width: calc(100% - 2px);
  border-top-left-radius: $border-radius-medium;
  border-top-right-radius: $border-radius-medium;
  cursor: pointer;
  flex-direction: column;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(0) !important; // Note: Disable the filter on the next image svg inline styling to blur edges
  }

  .image,
  .private {
    box-shadow: $box-shadow-listing-card;
    border-radius: $border-radius-large;
    pointer-events: none;
  }

  .blur-image {
    filter: blur(4px) !important; // Note: Blur on private listings
  }

  .image-wrapper {
    overflow: hidden;
    border-radius: $border-radius-large;

    img {
      transition: all .3s ease;
    }
  }

  .tag {
    padding: 4px 7px;
    margin-bottom: 5px;
  }

  .wrapper {
    display: flex;
    height: 100%;
    min-height: 7.5em;
    padding: .4em 0 .8em;
    flex-direction: column;

    div {
      img {
        filter: none;
      }
    }
  }

  .bottom-content {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  .row {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
  }

  a {
    text-decoration: none;
  }

  .street-address {
    margin: .5em 0;
    background: $color-modern-silver-gradient;
    -webkit-background-clip: text;
    background-clip: text;
    transition: color .2s ease-in-out;

    &:hover {
      color: $color-modern-silver;
    }
  }

  .pvr-date {
    font-size: 9px;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: .5em 1em;
    border-radius: 50px;
    background-color: $color-white-80;
    font-weight: 600;
  }

  &:hover {
    .image-wrapper {
      img {
        transform: scale(1.07);
      }
    }
  }
}

.map-panel-component {
  height: 390px;
}
