@import 'src/styles/imports';
@import 'src/styles/animations';

.btn-progress {
  position: relative;
  cursor: default;
  pointer-events: none;

  &::after {
    @include cover-screen;
    background-image: linear-gradient(-45deg, currentColor 25%, transparent 25%, transparent 50%, currentColor 50%, currentColor 75%, transparent 75%, transparent);
    background-size: 24px 24px;
    opacity: .1;
    animation: progressBarStripes .5s linear infinite;
  }
}
