@import 'src/styles/imports';

.component {
  font-size: 14px;
  display: flex;
  width: 60px;
  align-items: center;

  button {
    position: relative;
    padding: .5em 0;
    border: 0;
    margin: 0 !important; // sass-lint:disable-line no-important
    color: $color-black-50;

    &.active {
      color: var(--themes-colors-primary);
      font-weight: $font-weight-bold;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 4px;
        left: 3px;
        width: 17px;
        height: 2px;
        background-color: var(--themes-colors-primary);
      }
    }
  }

  span {
    margin: 0 5px;
    color: $color-black-50;
  }
}
