@import 'src/styles/imports';

.heading {
  font-size: 1.5em;
  margin: 1em 0;
  font-family: $font-family-serif;
  font-weight: $font-weight-bold;
}

.price-range {
  font-size: 1em;
  margin: 1.5em 0;

  .bold-price {
    font-weight: $font-weight-bold;
  }
}

.similar-listings {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include mobile {
    display: none;
  }
}

.card-wrapper {
  width: 100%;

  @media (min-width: 35em) { //above 560px
    width: calc(50% - 1em);
    margin-bottom: 1em;
  }

  @media (min-width: 50em) { //above 800px
    width: calc(33.33% - 1em);
  }
}

.minimal-listings {
  justify-content: start;

  .card-wrapper {
    margin-right: 1em;
  }
}

.mobile-carousel {
  @include not-mobile {
    display: none;
  }
}
